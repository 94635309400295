import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js/auto';
import '../App.css'; // Import the CSS file containing the .custom-center-text class

ChartJS.register(ArcElement, Tooltip, Legend);

function DoughnutChart({ chartData, centerTextTop, centerTextLeft }) {
  // Calculate the total userGain
  const totalUserGain = chartData.datasets[0].data.reduce((sum, value) => sum + value, 0);

  const options = {
    cutout: '80%', // Adjust the value to make the center hole smaller or larger
    plugins: {
      title: {
        display: true,
        text: 'Parts Sold', // Replace this with your desired title text
        font: {
          size: 14,
          weight: 'bold',
        },
        padding: {
          top: 0, // Adjust the top padding value to move the title up
          bottom: 0, // You can also adjust the bottom padding if needed
          right: 50,
        },
      },
      legend: {
        display: true,
        position: 'right',
        labels: {
          usePointStyle: true,
          generateLabels: (chart) => {
            const originalLabels = ChartJS.overrides.doughnut.plugins.legend.labels.generateLabels(chart);
            const newLabels = [...originalLabels];

            // Append percentage to the existing legend labels
            const userGainData = chartData.datasets[0].data;
            for (let i = 0; i < userGainData.length; i++) {
              const percentage = ((userGainData[i] / totalUserGain) * 100).toFixed(0);
              newLabels[i].text += ` ${percentage}%`;
            }

            return newLabels;
          },
        },
      },
      tooltip: {
        callbacks: {
          // Customize tooltip to display total userGain
          label: (context) => {
            const dataIndex = context.dataIndex;
            const dataset = context.dataset;
            const value = dataset.data[dataIndex];
            return `${value} (${((value / totalUserGain) * 100).toFixed(2)}%)`;
          },
        },
      },
    },
  };

  return (
    <div className="doughnut-chart-container">
      <div className="doughnut-chart">
        <Doughnut data={chartData} options={options} />
        <div className="center-text custom-center-text"style={{ top: centerTextTop, left: centerTextLeft }}>Total: {totalUserGain}</div>
      </div>
    </div>
  );
}

export default DoughnutChart;
