import React, { useEffect, useState, useRef } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Inject, Toolbar, Edit } from '@syncfusion/ej2-react-grids';
import { replenishData2, replenishGrid2, replenishcompGrid, replenishcompData } from '../data/dummy';
import { Header } from '../components';
import { useQueryClient } from '@tanstack/react-query';
import  axios  from 'axios';
import * as XLSX from 'xlsx';

// function binarySearch(arr, target) {
//   let left = 0;
//   let right = arr.length - 1;

//   while (left <= right) {
//     const mid = Math.floor((left + right) / 2);
//     const item = arr[mid];

//     if (item.TagDescription === target) {
//       return mid; // Found a matching item
//     }

//     if (item.TagDescription < target) {
//       left = mid + 1; // Search the right half
//     } else {
//       right = mid - 1; // Search the left half
//     }
//   }

//   return -1; // Item not found
// }
const Replenishment2 = () => {
  const [currentGrid, setCurrentGrid] = useState('replenish'); // Set the initial grid as 'replenish'
  const [apiData, setApiData] = useState([]);
  const [data, setData] = useState([]);
  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ['ExcelExport','Add', 'Edit', 'Delete']; // Remove 'Delete' and add 'ExcelExport' to export to Excel
  const editing = { allowDeleting: true, allowEditing: true };
  const customAttributes = { class: 'customcss' };
  const gridRef = useRef(null);
  const [dataFetched, setDataFetched] = useState(false); 
  const [filteredData, setFilteredData] = useState([]);
  const [uploadedPartIds, setUploadedPartIds] = useState([]);
  
  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary"});
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedDATA = XLSX.utils.sheet_to_json(sheet);
      const partIds = parsedDATA.map(row => row && row.PartId);
      setUploadedPartIds(partIds);
      setData(parsedDATA);
      //setDataFetched(false);
      console.log(parsedDATA);

      // Trigger data fetching when a file is uploaded
      //fetchData();
    }
  }

  useEffect(() => {
    if (uploadedPartIds.length > 0) {
      const cachedDataString = localStorage.getItem('mydata'); // Assuming you're getting cached data from localStorage
      if (typeof cachedDataString === 'string') {
        try {
          const cachedData = JSON.parse(cachedDataString);
          const filteredData = cachedData.filter(item => uploadedPartIds.includes(item.PartId));
          setFilteredData(filteredData);
          console.log('Filtered Data:', filteredData);
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      }
    }
  }, [uploadedPartIds]);
  const queryClient = useQueryClient();
  const cachedDataString = queryClient.getQueryData(['mydata']);
  const accessToken = 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiQnJ5YW5BYmFyYWJhciIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6Im5ic2FAY2l2aWNtZHNnLmNvbS5waCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJBY2Nlc3NBbGxCcmFuY2hlcyIsIkFkbWluaXN0cmF0b3IiLCJBc3NldE1hc3RlckRhdGFNYWludGVuYW5jZSIsIkFzc2V0VHJhbnNhY3Rpb25FbmNvZGVyIiwiRmluYW5jZURvY1JldmlzZXIiLCJGb3JlaWduUE9FbmNvZGVyIiwiR2VuZXJhbExlZGdlckNsb3NlciIsIkdlbmVyYWxMZWRnZXJFbmNvZGVyIiwiR2VuZXJhbExlZGdlclBvc3RlciIsIkdlbmVyYWxMZWRnZXJSZXBvcnRlciIsIkxvY2FsUE9FbmNvZGVyIiwiTWFjaGluZUVURkFwcHJvdmVyIiwiTWFjaGluZUludmVudG9yeVRyYW5zYWN0aW9uRW5jb2RlciIsIk1hY2hpbmVNYXN0ZXJEYXRhTWFpbnRlbmFuY2UiLCJNYWNoaW5lUE9FbmNvZGVyIiwiTWFjaGluZVNlcmlhbEFsbG9jYXRvciIsIk1hdGVyaWFsTWFjaGluZSIsIk1hdGVyaWFsc0RlcHRFbmNvZGVyIiwiTWF0ZXJpYWxzRGVwdFBvc3RlciIsIk1hdGxGaW5hbmNlUGVyaW9kRW5jb2RlciIsIlBhcnRzTWF0bEZpbmFuY2VQZXJpb2RWaWV3ZXIiLCJQYXJ0c1RyYW5zZmVyRm9yd2FyZGVyIiwiUFJTQWxsb2NhdG9yIiwiUmVudGFsRXF1aXBNYWludEVuY29kZXIiLCJSZW50YWxTYWxlc0NsZXJrIiwiU2VydmljZUpPRW5jb2RlciIsIlNlcnZpY2VNYXN0ZXJEYXRhTWFpbnRlbmFuY2UiLCJTZXJ2aWNlUHJvZHVjdFN0YWZmIiwiU2VydmljZVNlY3JldGFyeSIsIlNlcnZpY2VUZWNobmljaWFuIiwiU2VydmljZVdJUEVuY29kZXIiLCJXYXJlaG91c2UiLCJXYXJyYW50eUVuY29kZXIiXSwibmJmIjoxNjk2ODk5MjE3LCJleHAiOjE2OTY5ODU2MTcsImlzcyI6Imh0dHBzOi8vY2l2aWMuY2l2aWNtZHNnLmNvbS5waCIsImF1ZCI6Imh0dHBzOi8vY2l2aWMuY2l2aWNtZHNnLmNvbS5waCJ9.W53k3NiKCZa8rz_V2VquHeBAJ4oJA7NvQgef-NrcSWE';
  const config = {
    method: 'POST',
    responseType: 'json',
    headers: {
      'Authorization': accessToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "IsGroup": "No",
      // "page": page,
      // "pageSize": pageSize
    })
  };

  useEffect(() => {
    if (!dataFetched) { // Check if data has been fetched before making another request
      const fetchData = async () => {
        try {
          const response = await axios.post('https://system.civicmdsg.com.ph/CivicSystemWeb/api/ICPortalResources/ProductQtyFileExtract', {}, config);

          // Assuming response.data is an array of objects
          const cachedData = response.data;

          if (uploadedPartIds.length > 0) {
            const filteredData = cachedData.filter(item => uploadedPartIds.includes(item && item.PartId));
            setFilteredData(filteredData);
            console.log('Filtered Data:', filteredData);
          }

          setDataFetched(true); // Data has been fetched
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }
  }, [dataFetched, uploadedPartIds]);
  
  // Define headerStyle object here
  const headerStyle = {
    backgroundColor: '#c0c0c0',
    color: 'rgb(0, 0, 0)',
    paddingLeft: '10px',
  };

  const handleGridChange = (gridName) => {
    setCurrentGrid(gridName);
  };
  const clearFilters = () => {
    if (gridRef.current) {
      gridRef.current.clearFiltering(); // Call the clearFiltering method
    }
  };
  const FilterCell = (props) => {
    const column = props.column;
    return (
      <div>
        <button onClick={clearFilters}>Clear Filters</button>
        <input {...column.getFilterProps()} />
      </div>
    );
  };

  return (
    <div className="m-2 md:m-3 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="MONTHLY" title="REPLENISHMENT (1 WH)" />
      {/*<div className="grid-tab-buttons">
        
       <button className={currentGrid === 'replenish' ? 'active' : ''} onClick={() => handleGridChange('replenish')}>Replenish Grid</button>
        <button className={currentGrid === 'complete' ? 'active' : ''} onClick={() => handleGridChange('complete')}>Complete Grid</button>
        
      </div> */}
      <div className="grid-container"> {/* Add grid container here */}
        <GridComponent
          id="replenishmentGrid" // Add an ID to the GridComponent to reference it for exporting
          //dataSource={currentGrid === 'replenish' ? replenishData2 : replenishcompData}
          dataSource={filteredData}
          enableHover={true}
          selectionSettings={selectionsettings}
          toolbar={toolbarOptions}
          editSettings={editing}
          allowTextWrap={false}
          allowResizing={false}
          allowScrolling={true} // Enable horizontal scrolling
          allowFiltering={true}
          width="100%"
          ref={gridRef}
          filterSettings={{
            type: 'Excel', // Set the filter type to 'Menu' for filter menus
          }}
        >
           <input type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
        />
          <ColumnsDirective>
          {/* <ColumnDirective field="PartNumber" headerText="Part Number" textAlign="Right" width="100" />
            <ColumnDirective field="PartDescription" headerText="Part Description" width="150" />
            <ColumnDirective field="ProductCategory" headerText="Product Category" width="100" /> */}
            {currentGrid === 'replenish' ? (
              replenishGrid2.map((item, index) => (
                <ColumnDirective
                  key={index}
                  {...item}
                  header={index === 0 ? headerStyle : undefined} // Apply headerStyle to the first column
                  customAttributes={customAttributes}
                  allowEditing={item.field === 'APRD QTY'}
                  filterTemplate={FilterCell}
                  
                />
              ))
            ) : (
              replenishcompGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} customAttributes={customAttributes}  />
              ))
            )}
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, Filter, Resize, Edit]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default Replenishment2;