import React, { useEffect, useState, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import  axios  from 'axios';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, ContextMenu, Page, ExcelExport, Inject, Toolbar, Edit, Grid, Filter} from '@syncfusion/ej2-react-grids';
import { lubesorderData, lubesorderGrid } from '../data/dummy';
import { Header } from '../components';

const LubesOrder = () => {
  const [currentGrid, setCurrentGrid] = useState('lubesorder');
  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ['ExcelExport','Add', 'Edit', 'Delete'];
  const editing = { allowDeleting: true, allowEditing: true };
  const customAttributes = { class: 'customcss' };
  const gridRef = useRef(null);

  const [dataFetched, setDataFetched] = useState(false); 
  const [filteredData, setFilteredData] = useState([]);
  const queryClient = useQueryClient();
  const cachedDataString = queryClient.getQueryData(['mydata']);
  const accessToken = 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiQnJ5YW5BYmFyYWJhciIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6Im5ic2FAY2l2aWNtZHNnLmNvbS5waCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJBY2Nlc3NBbGxCcmFuY2hlcyIsIkFkbWluaXN0cmF0b3IiLCJBc3NldE1hc3RlckRhdGFNYWludGVuYW5jZSIsIkFzc2V0VHJhbnNhY3Rpb25FbmNvZGVyIiwiRmluYW5jZURvY1JldmlzZXIiLCJGb3JlaWduUE9FbmNvZGVyIiwiR2VuZXJhbExlZGdlckNsb3NlciIsIkdlbmVyYWxMZWRnZXJFbmNvZGVyIiwiR2VuZXJhbExlZGdlclBvc3RlciIsIkdlbmVyYWxMZWRnZXJSZXBvcnRlciIsIkxvY2FsUE9FbmNvZGVyIiwiTWFjaGluZUVURkFwcHJvdmVyIiwiTWFjaGluZUludmVudG9yeVRyYW5zYWN0aW9uRW5jb2RlciIsIk1hY2hpbmVNYXN0ZXJEYXRhTWFpbnRlbmFuY2UiLCJNYWNoaW5lUE9FbmNvZGVyIiwiTWFjaGluZVNlcmlhbEFsbG9jYXRvciIsIk1hdGVyaWFsTWFjaGluZSIsIk1hdGVyaWFsc0RlcHRFbmNvZGVyIiwiTWF0ZXJpYWxzRGVwdFBvc3RlciIsIk1hdGxGaW5hbmNlUGVyaW9kRW5jb2RlciIsIlBhcnRzTWF0bEZpbmFuY2VQZXJpb2RWaWV3ZXIiLCJQYXJ0c1RyYW5zZmVyRm9yd2FyZGVyIiwiUFJTQWxsb2NhdG9yIiwiUmVudGFsRXF1aXBNYWludEVuY29kZXIiLCJSZW50YWxTYWxlc0NsZXJrIiwiU2VydmljZUpPRW5jb2RlciIsIlNlcnZpY2VNYXN0ZXJEYXRhTWFpbnRlbmFuY2UiLCJTZXJ2aWNlUHJvZHVjdFN0YWZmIiwiU2VydmljZVNlY3JldGFyeSIsIlNlcnZpY2VUZWNobmljaWFuIiwiU2VydmljZVdJUEVuY29kZXIiLCJXYXJlaG91c2UiLCJXYXJyYW50eUVuY29kZXIiXSwibmJmIjoxNjk2NzM0NzExLCJleHAiOjE2OTY4MjExMTEsImlzcyI6Imh0dHBzOi8vY2l2aWMuY2l2aWNtZHNnLmNvbS5waCIsImF1ZCI6Imh0dHBzOi8vY2l2aWMuY2l2aWNtZHNnLmNvbS5waCJ9.JBXx3r-OUvQXbtSVB4S2UnHqHZ3lSWRV927h5oyV3PE';
  const config = {
    method: 'POST',
    responseType: 'json',
    headers: {
      'Authorization': accessToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "IsGroup": "No",
      // "page": page,
      // "pageSize": pageSize
    })
  };
  
  useEffect(() => {
    if (typeof cachedDataString === 'string') {
      try {
        const cachedData = JSON.parse(cachedDataString);
        console.log('Cached Data:', cachedData);
        let filteredData = [];
        //filteredData = cachedData.filter(item => item.ProductCategory === 'VSG');
        filteredData = cachedData.filter(item => item.ProductCategory === 'YPG' || item.ProductCategory === 'VOG' || item.ProductCategory === 'VLG');
        setFilteredData(filteredData);
        setDataFetched(true); // Data has been fetched
        console.log('Filtered Data:', filteredData);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    } else {
      if (!dataFetched) { // Check if data has been fetched before making another request
        const fetchData = async () => {
          try {
            const response = await axios.post('https://system.civicmdsg.com.ph/CivicSystemWeb/api/ICPortalResources/ProductQtyFileExtract', {}, config);
            // const response1 = await fetch('https://system.civicmdsg.com.ph/CivicSystemWeb/api/ICPortalResources/ProductQtyFileExtract', config);
            
            // const responseData1 = await response1.json();
            // console.log('Response Data 1:', responseData1);

            if (response && response.data) {
              const cachedData = response.data;
              //const filteredData = cachedData.filter(item => item && item.ProductCategory === 'LUV');
              const filteredData = cachedData.filter(item => item && item.ProductCategory === 'YPG' || item && item.ProductCategory === 'VOG' || item && item.ProductCategory === 'VLG' || item && item.ProductCategory === 'VTG' || item && item.ProductCategory === 'VOH' || item && item.ProductCategory === 'DOB' || item && item.ProductCategory === 'SDG' || item && item.ProductCategory === 'DOP' || item && item.ProductCategory === 'SOG' || item && item.ProductCategory === 'ZZZ' || item && item.ProductCategory === 'YCG' || item && item.ProductCategory === 'NIG');
              setFilteredData(filteredData);
              setDataFetched(true); // Data has been fetched
              console.log('Filtered Data:', filteredData);
            } else {
              console.error('Error fetching data: Response data is null or undefined');
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
      }
    }
  }, [cachedDataString, config, dataFetched]);



  const handleGridChange = (gridName) => {
    setCurrentGrid(gridName);
  };
  const clearFilters = () => {
    if (gridRef.current) {
      gridRef.current.clearFiltering(); // Call the clearFiltering method
    }
  };
  const FilterCell = (props) => {
    const column = props.column;
    return (
      <div>
        <button onClick={clearFilters}>Clear Filters</button>
        <input {...column.getFilterProps()} />
      </div>
    );
  };
 
  
  
  
  
  
  
  

  return (
    <div className="m-2 md:m-5 mt-24 p-2 md:p-5 bg-white rounded-3xl">
      <Header category="Lubes" title="Order" />
      <div className="grid-container">
        <GridComponent
          id="lubesorderGrid"
          dataSource={lubesorderData}
          enableHover={true}
          selectionSettings={selectionsettings}
          toolbar={toolbarOptions}
          editSettings={editing}
          allowTextWrap={false}
          allowResizing={false}
          allowScrolling={true}
          allowFiltering={false}
          width="180%"
          ref={gridRef}
          filterSettings={{
            type: 'Excel', // Set the filter type to 'Menu' for filter menus
          }}
         
         
        >
          <ColumnsDirective>
  {currentGrid === 'lubesorder' ? (
    lubesorderGrid.map((item, index) => (
      <ColumnDirective
        key={index}
        field={item.field}
        headerText={item.headerText}
        textAlign={item.textAlign}
        width={item.width}
        format={item.format}
        customAttributes={customAttributes}
        allowEditing={item.field === 'APRD QTY'}
        filterTemplate={FilterCell}
      
              
       /* wrapText={
          item.headerText === '12 MANILA' ||
          item.headerText === '15 LEGAZPI' ||
          item.headerText === '16 LAUNION' ||
          item.headerText === '17 BATANGAS' ||
          item.headerText === '18 ISABELA' ||
          item.headerText === '19 PPRINCESSA' ||
          item.headerText === '40 PHILEX' ||
          item.headerText === '44 TEAMSUAL' ||
          item.headerText === '47 RTN' ||
          item.headerText === '48 BATARAZA' ||
          item.headerText === '50 SUNWEST' ||
          item.headerText === '90 CDO' ||
          item.headerText === '91 VALENCIA' ||
          item.headerText === '30 DAVAO' ||
          item.headerText === '20 CEBU' ||
          item.headerText === '28 ILOILO' ||
          item.headerText === '21 SANCARLOS' ||
          item.headerText === '29 CCC' ||
          item.headerText === '31 APEX' ||
          item.field === '22 SURIGAO' ||
          item.headerText === '26 CLAVER' ||
          item.headerText === '23 CARRASCAL' ||
          item.headerText === '24 SRMETALS' ||
          item.headerText === '27 NICKELBASE' ||
          item.field === '52 TMC' ||
          item.headerText === '54 HMC/CMC'
        }*/
      />
    ))
  ) : null}
</ColumnsDirective>

          <Inject services={[Toolbar, ExcelExport, Resize, Edit, Filter]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default LubesOrder;
