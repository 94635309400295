// IndexedDB.jsx
import React, { useState, useEffect } from 'react';

const IndexedDB = () => {
  const [db, setDB] = useState(null);

  const initializeDB = () => {
    const request = window.indexedDB.open('MyDatabase', 1);

    request.onsuccess = (event) => {
      const database = event.target.result;
      setDB(database);
    };

    request.onerror = (event) => {
      console.error('Error opening database:', event.target.error);
    };

    request.onupgradeneeded = (event) => {
      const database = event.target.result;
      // Simplify object store creation with keyPath 'id' and autoIncrement
      database.createObjectStore('MyObjectStore', { keyPath: 'id', autoIncrement: true });
      // You can create additional indexes for other properties if needed
    };
  };

  const storeDataInIndexedDB = (dataArray, partIdsToFilter) => {
    if (!db) {
      console.error('IndexedDB is not initialized.');
      return;
    }
  
    if (!dataArray || !Array.isArray(dataArray)) {
      console.error('dataArray is not properly initialized.');
      return;
    }
  
    if (!partIdsToFilter || !Array.isArray(partIdsToFilter)) {
      console.error('partIdsToFilter is not properly initialized.');
      return;
    }
  
    const transaction = db.transaction(['MyObjectStore'], 'readwrite');
    const objectStore = transaction.objectStore('MyObjectStore');
  
    // Clear the object store before adding new data
    objectStore.clear();
  
    // Filter data based on Part Id
    const filteredData = dataArray.filter(dataObject =>
      partIdsToFilter.includes(dataObject && dataObject['Part Id'])
    );
  
    filteredData.forEach(dataObject => {
      // Log the data object to check its structure before attempting to add to IndexedDB
      console.log('Data Object:', dataObject);
  
      const request = objectStore.add(dataObject);
  
      request.onsuccess = () => {
        console.log('Data added to IndexedDB:', dataObject);
      };
  
      request.onerror = (event) => {
        console.error('Error adding data to IndexedDB:', event.target.error);
      };
    });
  };
  
  
  
  

  const getDataFromIndexedDB = () => {
    if (db) {
      const transaction = db.transaction(['MyObjectStore'], 'readonly');
      const objectStore = transaction.objectStore('MyObjectStore');
      const getAllRequest = objectStore.getAll();

      getAllRequest.onsuccess = () => {
        const allData = getAllRequest.result;
        console.log('Data retrieved from IndexedDB:', allData);
      };

      getAllRequest.onerror = (event) => {
        console.error('Error retrieving data from IndexedDB:', event.target.error);
      };
    } else {
      console.error('IndexedDB is not initialized.');
    }
  };

  useEffect(() => {
    initializeDB();
  }, []);

  // Return the functions you want to use in the component
  return {
    initializeDB,
    storeDataInIndexedDB,
    getDataFromIndexedDB,
  };
};

export default IndexedDB;
