import { ColumnDirective, ColumnsDirective, GridComponent, Inject } from '@syncfusion/ej2-react-grids';
import { DetailRow } from '@syncfusion/ej2-react-grids';
import React, { useState, useEffect } from 'react';
import { VolvoCEGrid, VolvoCEData, cluster1Data, cluster2Data, cluster3Data } from '../data/dummy';
import { Header } from '../components';
import image from '../data/image.png';
import BarChart from "../components/BarChart";
import { UserData } from "../data/Data";
import { useQueryClient } from '@tanstack/react-query';
import  axios  from 'axios';


const clusterContainerStyle = {
  width: '90%',
};

const mainGridContainerStyle = {
  width: '100%',
};

// Override styles for the expand icons to make them transparent
const expandIconStyle = {
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
};

function App() {
  const [dataFetched, setDataFetched] = useState(false); 
  const [filteredData, setFilteredData] = useState([]);
  const queryClient = useQueryClient();
  const cachedDataString = queryClient.getQueryData(['mydata']);
  const accessToken = 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiQnJ5YW5BYmFyYWJhciIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6Im5ic2FAY2l2aWNtZHNnLmNvbS5waCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJBY2Nlc3NBbGxCcmFuY2hlcyIsIkFkbWluaXN0cmF0b3IiLCJBc3NldE1hc3RlckRhdGFNYWludGVuYW5jZSIsIkFzc2V0VHJhbnNhY3Rpb25FbmNvZGVyIiwiRmluYW5jZURvY1JldmlzZXIiLCJGb3JlaWduUE9FbmNvZGVyIiwiR2VuZXJhbExlZGdlckNsb3NlciIsIkdlbmVyYWxMZWRnZXJFbmNvZGVyIiwiR2VuZXJhbExlZGdlclBvc3RlciIsIkdlbmVyYWxMZWRnZXJSZXBvcnRlciIsIkxvY2FsUE9FbmNvZGVyIiwiTWFjaGluZUVURkFwcHJvdmVyIiwiTWFjaGluZUludmVudG9yeVRyYW5zYWN0aW9uRW5jb2RlciIsIk1hY2hpbmVNYXN0ZXJEYXRhTWFpbnRlbmFuY2UiLCJNYWNoaW5lUE9FbmNvZGVyIiwiTWFjaGluZVNlcmlhbEFsbG9jYXRvciIsIk1hdGVyaWFsTWFjaGluZSIsIk1hdGVyaWFsc0RlcHRFbmNvZGVyIiwiTWF0ZXJpYWxzRGVwdFBvc3RlciIsIk1hdGxGaW5hbmNlUGVyaW9kRW5jb2RlciIsIlBhcnRzTWF0bEZpbmFuY2VQZXJpb2RWaWV3ZXIiLCJQYXJ0c1RyYW5zZmVyRm9yd2FyZGVyIiwiUFJTQWxsb2NhdG9yIiwiUmVudGFsRXF1aXBNYWludEVuY29kZXIiLCJSZW50YWxTYWxlc0NsZXJrIiwiU2VydmljZUpPRW5jb2RlciIsIlNlcnZpY2VNYXN0ZXJEYXRhTWFpbnRlbmFuY2UiLCJTZXJ2aWNlUHJvZHVjdFN0YWZmIiwiU2VydmljZVNlY3JldGFyeSIsIlNlcnZpY2VUZWNobmljaWFuIiwiU2VydmljZVdJUEVuY29kZXIiLCJXYXJlaG91c2UiLCJXYXJyYW50eUVuY29kZXIiXSwibmJmIjoxNjk2Njk2NzU5LCJleHAiOjE2OTY3ODMxNTksImlzcyI6Imh0dHBzOi8vY2l2aWMuY2l2aWNtZHNnLmNvbS5waCIsImF1ZCI6Imh0dHBzOi8vY2l2aWMuY2l2aWNtZHNnLmNvbS5waCJ9.hVF1HWvN9CXO_u45m-9pV2DRyGfPD_iDuhDAxTAKhJI';
  const config = {
    method: 'POST',
    responseType: 'json',
    headers: {
      'Authorization': accessToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "IsGroup": "No",
      // "page": page,
      // "pageSize": pageSize
    })
  };
  
  useEffect(() => {
    if (typeof cachedDataString === 'string') {
      try {
        const cachedData = JSON.parse(cachedDataString);
        console.log('Cached Data:', cachedData);
        let filteredData = [];
        filteredData = cachedData.filter(item => item.ProductCategory === 'VSG');
        setFilteredData(filteredData);
        setDataFetched(true); // Data has been fetched
        console.log('Filtered Data:', filteredData);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    } else {
      if (!dataFetched) { // Check if data has been fetched before making another request
        const fetchData = async () => {
          try {
            const response = await axios.post('https://system.civicmdsg.com.ph/CivicSystemWeb/api/ICPortalResources/ProductQtyFileExtract', {}, config);
            if (response && response.data) {
              const cachedData = response.data;
              const filteredData = cachedData.filter(item => item && item.ProductCategory === 'LUV');
              setFilteredData(filteredData);
              setDataFetched(true); // Data has been fetched
              console.log('Filtered Data:', filteredData);
            } else {
              console.error('Error fetching data: Response data is null or undefined');
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
      }
    }
  }, [cachedDataString, config, dataFetched]);
  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.month),
    datasets: [
            {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain),
        backgroundColor: ["blue"],
        barPercentage: 0.6,
        categoryPercentage: 1,
      },
      {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain1),
        backgroundColor: ["blue"],
        barPercentage: 0.6,
        categoryPercentage: 1,
      },
      {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain2),
        backgroundColor: ["blue"],
        barPercentage: 0.6,
        categoryPercentage: 1,
      },
      {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain3),
        backgroundColor: ["blue"],
        barPercentage: 0.6,
        categoryPercentage: 1,
      },
      {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain4),
        backgroundColor: ["blue"],
        barPercentage: 0.6,
        categoryPercentage: 1,
      },
      // Add more datasets as needed for other userGain properties
    ],
  });
  const childGridColumns = [
    // ... existing childGridColumns ...
  ];
  const expandIconTemplate = (props) => {
    // If the row is expanded
    if (props.isExpand) {
      return (
        <img src={image.png} alt="Expanded Icon" style={{ width: '16px', height: '16px', cursor: 'pointer' }} />
      );
    } else {
      // If the row is not expanded
      return (
        <img src={image.png} alt="Collapsed Icon" style={{ width: '16px', height: '16px', cursor: 'pointer' }} />
      );
    }
  };

  const detailTemplate = (props) => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%' }}>
          <h4 style={{ fontWeight: 'bold', fontSize: '18px' }}>Cluster 1 Manila:</h4>
          <div style={clusterContainerStyle}>
            <GridComponent dataSource={cluster1Data} childGrid={childGridColumns}>
              <ColumnsDirective>
                {childGridColumns.map((column) => (
                  <ColumnDirective
                    key={column.field}
                    field={column.field}
                    headerText={column.headerName}
                    width={column.width}
                    format={column.format}
                  />
                ))}
              </ColumnsDirective>
            </GridComponent>
          </div>
          <h4 style={{ fontWeight: 'bold', fontSize: '18px' }}>Cluster 2 Cagayan De Oro:</h4>
          <div style={clusterContainerStyle}>
            <GridComponent dataSource={cluster2Data} childGrid={childGridColumns}>
              <ColumnsDirective>
                {childGridColumns.map((column) => (
                  <ColumnDirective
                    key={column.field}
                    field={column.field}
                    headerText={column.headerName}
                    width={column.width}
                    format={column.format}
                  />
                ))}
              </ColumnsDirective>
            </GridComponent>
          </div>
          <h4 style={{ fontWeight: 'bold', fontSize: '18px' }}>Cluster 3 Surigao:</h4>
          <div style={clusterContainerStyle}>
            <GridComponent dataSource={cluster3Data} childGrid={childGridColumns}>
              <ColumnsDirective>
                {childGridColumns.map((column) => (
                  <ColumnDirective
                    key={column.field}
                    field={column.field}
                    headerText={column.headerName}
                    width={column.width}
                    format={column.format}
                  />
                ))}
              </ColumnsDirective>
            </GridComponent>
          </div>
        </div>
        <div style={{ width: '50%', paddingLeft: '1px' }}>
          <img src={image} alt="Lubes Monitoring" />
        </div>
      </div>
    );
  };
  console.log('Cached Data:', cachedDataString)
  console.log('Filtered Data:', filteredData);
  return (
    <div className="mt-6">
      <div className="flex flex-wrap lg:flex-nowrap justify-left">
      {/* <div style={{ width: 700, height: 100,  }}>
          <BarChart chartData={userData} />
          
        </div> */}
      </div>
      <div className="m-1 md:m-1 p-1 md:p-2 bg-white rounded-3xl ">
        <Header category="" title="Lubes Monitoring" />
        <div style={mainGridContainerStyle}>
          <GridComponent dataSource={filteredData} detailTemplate={detailTemplate} detailRowTemplate={expandIconStyle}>
            <ColumnsDirective>
            <ColumnDirective field="PartId" headerText="Part ID" textAlign="Right" width="100" />
          <ColumnDirective field="PartNumber" headerText="Part Number" textAlign="Right" width="100" />
             <ColumnDirective field="PartDescription" headerText="Part Description" width="250" />
            <ColumnDirective field="ProductCategory" headerText="Product Category" width="100" />
              {VolvoCEGrid.map((column) => (
                <ColumnDirective
                  key={column.field}
                  field={column.field}
                  headerText={column.headerText}
                  textAlign={column.textAlign}
                  width={column.width}
                  format={column.format}
                />
              ))}
            </ColumnsDirective>
            <Inject services={[DetailRow]} detailRowTemplate={expandIconTemplate} />
          </GridComponent>
        </div>
      </div>
    </div>
  );
}

export default App;