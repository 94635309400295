import React from 'react';
import  ReactDOM  from 'react-dom';
import './index.css';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import App from './App';
import { ContextProvider } from './contexts/ContextProvider';

const queryClient = new QueryClient()


ReactDOM.render(
<QueryClientProvider client={queryClient}>
    <ContextProvider>
        <App />
    </ContextProvider>
</QueryClientProvider>, 
document.getElementById('root'));
