import React, { useEffect, useState, useRef } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import  axios  from 'axios';
import { GridComponent, ColumnsDirective, ColumnDirective, ExcelExport, Inject, Toolbar, Resize } from '@syncfusion/ej2-react-grids';
import { mrpGrid, mrpData, clusterGrid } from '../data/dummy';
import { Header } from '../components';
import * as XLSX from 'xlsx';


const Cluster = () => {
  

const result = useQuery(['cluster'], async () => {
  const response = await fetch('https://system.civicmdsg.com.ph/CivicSystemWeb/api/ICPortalResources/ProductQtyFileExtract', config);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
}, {
  staleTime: 60000 * 24, // Data will be considered fresh for 60 seconds
});
console.log('Result:', result);
const { data1 } = result;

const [filteredData, setFilteredData] = useState([]);
const [cachedData, setCachedData] = useState([]); // Add state to store cached data

// ... (rest of your code)


const handleClusterClick = (clusterNumber) => {
  let clusterData = [];

  switch (clusterNumber) {
    case 1:
      clusterData = ['12', '14', '18', '19', '15', '17', '16', '40', '47', '48', '50', '10VARIOUS', '44'];
      break;
    case 2:
      clusterData = ['90', '91', '30', '20', '28', '21', '29', '31'];
      break;
    case 3:
      clusterData = ['22', '26', '23', '52', '54', '24', '27'];
      break;
    default:
      break;
  }

  const cachedData = queryClient.getQueryData(['cluster']); // Access the cached data
  //const cachedData = JSON.parse(cachedDataString);
  console.log('Cached Data:', cachedData);
  const filteredData = cachedData.filter(item => clusterData.includes(item && item.WarehouseId));
  setFilteredData(filteredData);

  console.log('Filtered Data:', filteredData);
};
    const selectionsettings = { persistSelection: true };
    const toolbarOptions = ['ExcelExport']; // Add other desired options for the toolbar if needed
    const customAttributes = { class: 'customcss' };
    const [data, setData] = useState([]);
    const [dataFetched, setDataFetched] = useState(false); 
    const [uploadData, setUploadData] = useState([]);
    const [uploadedPartIds, setUploadedPartIds] = useState([]);
    
    const handleFileUpload = (e) => {
      const reader = new FileReader();
      reader.readAsBinaryString(e.target.files[0]);
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary"});
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedDATA = XLSX.utils.sheet_to_json(sheet);
        const partIds = parsedDATA.map(row => row && row.PartId);
        setUploadedPartIds(partIds);
        setData(parsedDATA);
        //setDataFetched(false);
        console.log(parsedDATA);
  
        // Trigger data fetching when a file is uploaded
        //fetchData();
      }
    }
  
    useEffect(() => {
      if (uploadedPartIds.length > 0) {
        const cachedDataString = localStorage.getItem('mydata'); // Assuming you're getting cached data from localStorage
        if (typeof cachedDataString === 'string') {
          try {
            const cachedData = JSON.parse(cachedDataString);
            const uploadData = cachedData.filter(item => uploadedPartIds.includes(item.PartId));
            setUploadData(uploadData);
            console.log('Filtered Upload Data:', uploadData);
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        }
      }
    }, [uploadedPartIds]);
    const queryClient = useQueryClient();
    const cachedDataString = queryClient.getQueryData(['mydata']);
    const accessToken = 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiQnJ5YW5BYmFyYWJhciIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6Im5ic2FAY2l2aWNtZHNnLmNvbS5waCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJBY2Nlc3NBbGxCcmFuY2hlcyIsIkFkbWluaXN0cmF0b3IiLCJBc3NldE1hc3RlckRhdGFNYWludGVuYW5jZSIsIkFzc2V0VHJhbnNhY3Rpb25FbmNvZGVyIiwiRmluYW5jZURvY1JldmlzZXIiLCJGb3JlaWduUE9FbmNvZGVyIiwiR2VuZXJhbExlZGdlckNsb3NlciIsIkdlbmVyYWxMZWRnZXJFbmNvZGVyIiwiR2VuZXJhbExlZGdlclBvc3RlciIsIkdlbmVyYWxMZWRnZXJSZXBvcnRlciIsIkxvY2FsUE9FbmNvZGVyIiwiTWFjaGluZUVURkFwcHJvdmVyIiwiTWFjaGluZUludmVudG9yeVRyYW5zYWN0aW9uRW5jb2RlciIsIk1hY2hpbmVNYXN0ZXJEYXRhTWFpbnRlbmFuY2UiLCJNYWNoaW5lUE9FbmNvZGVyIiwiTWFjaGluZVNlcmlhbEFsbG9jYXRvciIsIk1hdGVyaWFsTWFjaGluZSIsIk1hdGVyaWFsc0RlcHRFbmNvZGVyIiwiTWF0ZXJpYWxzRGVwdFBvc3RlciIsIk1hdGxGaW5hbmNlUGVyaW9kRW5jb2RlciIsIlBhcnRzTWF0bEZpbmFuY2VQZXJpb2RWaWV3ZXIiLCJQYXJ0c1RyYW5zZmVyRm9yd2FyZGVyIiwiUFJTQWxsb2NhdG9yIiwiUmVudGFsRXF1aXBNYWludEVuY29kZXIiLCJSZW50YWxTYWxlc0NsZXJrIiwiU2VydmljZUpPRW5jb2RlciIsIlNlcnZpY2VNYXN0ZXJEYXRhTWFpbnRlbmFuY2UiLCJTZXJ2aWNlUHJvZHVjdFN0YWZmIiwiU2VydmljZVNlY3JldGFyeSIsIlNlcnZpY2VUZWNobmljaWFuIiwiU2VydmljZVdJUEVuY29kZXIiLCJXYXJlaG91c2UiLCJXYXJyYW50eUVuY29kZXIiXSwibmJmIjoxNjk3NjgzMjQxLCJleHAiOjE2OTc3Njk2NDEsImlzcyI6Imh0dHBzOi8vY2l2aWMuY2l2aWNtZHNnLmNvbS5waCIsImF1ZCI6Imh0dHBzOi8vY2l2aWMuY2l2aWNtZHNnLmNvbS5waCJ9.PQWRFiWjr_swnjwSeyBJh949DEL_tbq_PskHSLl4fGs';
    const config = {
      method: 'POST',
      responseType: 'json',
      headers: {
        'Authorization': accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "IsGroup": "No",
        // "page": page,
        // "pageSize": pageSize
      })
    };
    useEffect(() => {
        if (!dataFetched) { // Check if data has been fetched before making another request
          const fetchData = async () => {
            try {
              const response = await axios.post('https://system.civicmdsg.com.ph/CivicSystemWeb/api/ICPortalResources/ProductQtyFileExtract', {}, config);
    
              // Assuming response.data is an array of objects
              const cachedData = response.data;
    
              if (uploadedPartIds.length > 0) {
                const uploadData = cachedData.filter(item => uploadedPartIds.includes(item && item.PartId));
                setUploadData(uploadData);
                console.log('Filtered Upload Data:', uploadData);
              }
    
              setDataFetched(true); // Data has been fetched
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
          fetchData();
        }
      }, [dataFetched, uploadedPartIds]);

      const headerStyle = {
        backgroundColor: '#c0c0c0',
        color: 'rgb(0, 0, 0)',
        paddingLeft: '10px',
      };

  return (
    <div className="m-2 md:m-3 mt-24 p-2 md:p-10 bg-white rounded-3xl grid-container"> {/* Add grid container here */}
      <Header category="" title="Clusters" />
      <input type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
        />
         <button onClick={() => handleClusterClick(1)} className="ml-2" style={{ padding: '8px 16px', border: '1px solid #ccc' }}>Cluster 1</button>
                <button onClick={() => handleClusterClick(2)} className="ml-2" style={{ padding: '8px 16px', border: '1px solid #ccc' }}>Cluster 2</button>
                <button onClick={() => handleClusterClick(3)} className="ml-2" style={{ padding: '8px 16px', border: '1px solid #ccc' }}>Cluster 3</button>
      <GridComponent
        id="mrpGrid"
        dataSource={filteredData}
        enableHover={true}
        selectionSettings={selectionsettings}
        toolbar={toolbarOptions}
        allowTextWrap={false}
        allowResizing={false}
        allowScrolling={true}
        width="100%"
      >
       
       
        <ColumnsDirective>
          {clusterGrid.map((item, index) => (
            <ColumnDirective
              key={index}
              {...item}
              header={index === 0 ? headerStyle : undefined}
              customAttributes={customAttributes}
              
            />
            
          ))}
        </ColumnsDirective>
        <Inject services={[Toolbar, ExcelExport, Resize]} />
      </GridComponent>
    </div>
  )
}

export default Cluster
