import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import {  Footer, Sidebar, ThemeSettings } from './components';
import { Vtc, Ud, Sdlg, Local, Vce, Cluster, Mrp2, Mrp, Consignment2, Consignment, Login, LubesOrder, Vhs, Pms, Ptrf, Ptrf2, Replenishment2, Replenishment, Aging, VolvoCE,  Orders, Calendar,  Stacked, Pyramid,  Kanban, Line, Area, Bar, Pie, Financial, ColorPicker, ColorMapping, Editor } from './pages';
import './App.css';

import { useStateContext } from './contexts/ContextProvider';

const App = () => {
  const ParentComponent = () => {
    const handleLogin = (username,password) => {

      var username;
      var password;

      //dito ang fetch sa api ni civicweb ipapasa ang username password

      //error report

      //storage / or session local storage

      // Handle the access token received from the login component
      // console.log('Received access token:', accessToken);
      // You can perform further actions here, such as storing the token in state or local storage.
    };
  
    return (
      <div>
        {/* Render the Login component and pass the handleLogin function */}
        <Login onLogin={handleLogin} />
      </div>
    );
  };
  const { setCurrentColor, setCurrentMode, currentMode, currentColor, themeSettings, setThemeSettings, isSidebarExpanded, handleToggleSidebar } =
    useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
            <TooltipComponent content="Settings" position="Top">
              <button
                type="button"
                onClick={() => setThemeSettings(true)}
                style={{ background: currentColor, borderRadius: '50%' }}
                className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div>
          {isSidebarExpanded ? (
            <div className="w-50 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
              <Sidebar />
            </div>
          ) : (
            <div className="w-5% dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}
          <div
            className={
              isSidebarExpanded
                ? 'dark:bg-main-dark-bg bg-main-bg min-h-screen md:ml-50 w-full'
                : 'bg-main-bg dark:bg-main-dark-bg w-full min-h-screen flex-2'
            }
          >
           
            <div>
              {themeSettings && <ThemeSettings />}
              <Routes>
                {/* dashboard  */}
                <Route path="/" element={<Login />} />
                <Route path="/aging" element={<Aging />} />
                <Route path="/volvo-ce" element={<Vce />} />
                <Route path="/volvo-trucks" element={<Vtc />} />
                <Route path="/ud" element={<Ud />} />
                <Route path="/sdlg" element={<Sdlg />} />
                <Route path="/local" element={<Local />} />
                <Route path="/replenishment" element={<Replenishment />} />
                <Route path="/replenishment2" element={<Replenishment2 />} />
                <Route path="/ptrf" element={<Ptrf />} />
                <Route path="/ptrf2" element={<Ptrf2 />} />
            <Route path="/pms" element={<Pms />} /> 
                <Route path="/volvohosesystem" element={<Vhs />} />
                <Route path="/lubesorder" element={<LubesOrder />} />
                <Route path="/consignment" element={<Consignment />} />
                <Route path="/consignment2" element={<Consignment2 />} />
                <Route path="/mrp" element={<Mrp />} />
                <Route path="/mrp2" element={<Mrp2 />} />
                <Route path="/cluster" element={<Cluster />} />



                {/* pages  */}
                <Route path="/orders" element={<Orders />} />
                

                {/* apps  */}
                <Route path="/kanban" element={<Kanban />} />
                <Route path="/editor" element={<Editor />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/color-picker" element={<ColorPicker />} />

                {/* charts  */}
                <Route path="/line" element={<Line />} />
                <Route path="/area" element={<Area />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/financial" element={<Financial />} />
                <Route path="/color-mapping" element={<ColorMapping />} />
                <Route path="/pyramid" element={<Pyramid />} />
                <Route path="/stacked" element={<Stacked />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
