import React, { useState } from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';
import { GoPrimitiveDot } from 'react-icons/go';
import { IoIosMore } from 'react-icons/io';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import BarChart from "../components/BarChart";
import DoughnutChart from "../components/DoughnutChart";
import DoughnutChart2 from "../components/DoughnutChart2";
import DoughnutChart3 from "../components/DoughnutChart3";
import { UserData } from "../data/Data";
import { UserData1 } from "../data/Data"; // Assuming UserData1 is similar to UserData
import { UserData2 } from "../data/Data";
import '../App.css'; // Import the CSS file containing the .custom-center-text class
import { Stacked, Pie, Button, LineChart, SparkLine } from '../components';
import { earningData, earningData1, medicalproBranding, recentTransactions, weeklyStats, dropdownData, SparklineAreaData, ecomPieChartData } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';

// Function to format the chart data
function Aging() {
  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.month),
    datasets: [
            {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain),
        backgroundColor: ["blue"],
        barPercentage: 0.6,
        categoryPercentage: 1,
      },
      {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain1),
        backgroundColor: ["blue"],
        barPercentage: 0.6,
        categoryPercentage: 1,
      },
      {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain2),
        backgroundColor: ["blue"],
        barPercentage: 0.6,
        categoryPercentage: 1,
      },
      {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain3),
        backgroundColor: ["blue"],
        barPercentage: 0.6,
        categoryPercentage: 1,
      },
      {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain4),
        backgroundColor: ["blue"],
        barPercentage: 0.6,
        categoryPercentage: 1,
      },
      // Add more datasets as needed for other userGain properties
    ],
  });

  const [userData1, setUserData1] = useState({
    labels: UserData1.map((data) => data.month),
    datasets: [
      {
        data: UserData1.map((data) => data.userGain),
        backgroundColor: ["red", "green", "blue", "orange", "purple"], // Set custom colors
      },
      // Add more datasets as needed for other properties
    ],
  });

  const [userData2, setUserData2] = useState({
    labels: UserData2.map((data) => data.month),
    datasets: [
      {
        data: UserData2.map((data) => data.userGain),
        backgroundColor: ["black", "purple", "lightblue", "blue", "gray"], // Set custom colors
      },
       // Add more datasets as needed for other properties
          ],
  });
  return (
    <>
      <div className="flex m-1 flex-wrap justify-start items-left">
        {earningData.map((item) => (
          <div
            key={item.title}
            className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-58 p-4 pt-9 rounded-2xl"
          >
            <div className="flex items-center mt-1">
              <button
                type="button"
                style={{
                  color: item.iconColor,
                  backgroundColor: item.iconBg,
                  fontSize: '48px' // Adjust the font size as desired, e.g., '24px', '2rem', etc.
                }}
                className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
              >
                {item.icon}
              </button>
              <div className="ml-2 flex flex-col">
                <p className="text-sm text-gray-400 mt-1">{item.title}</p>
                <p className="text-xs">{item.amount}</p>
                <p className={`text-sm text-${item.pcColor}`}>{item.percentage}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex m-1 flex-wrap justify-start items-left">
  {earningData1.map((item) => (
    <div
      key={item.title}
      className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-42 p-4 pt-1 rounded-2xl"
    >
      <div className="flex items-center mt-1">
        <button
          type="button"
          style={{
            color: item.iconColor,
            backgroundColor: item.iconBg,
            fontSize: '24px' // Adjust the font size as desired, e.g., '24px', '2rem', etc.
          }}
          className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
        >
          {item.icon}
        </button>
        <div className="ml-2 flex flex-col">
          <p className="text-sm text-gray-400 mt-1">{item.title}</p>
          <p className="text-xs">{item.percentage}</p>
          
        </div>
      </div>
    </div>
  ))}
</div>
<div className="Aging">
        <div style={{ width: 700, height: 100 }}>
          <BarChart chartData={userData} />
          
        </div>
        <div style={{ fontSize: '12px', fontWeight: 'bold' }}><p>FILTER BY: LINE ITEM | QUANTITY | VALUES</p></div>

        <div style={{ display: 'flex', justifyContent: 'space-between', width: '900px' }}>
          
          <DoughnutChart chartData={userData2} centerTextTop="68%" centerTextLeft="22%" />
          <DoughnutChart2 chartData={userData2} centerTextTop="68%" centerTextLeft="45%" />
          <DoughnutChart3 chartData={userData2} centerTextTop="68%" centerTextLeft="68%" />
        </div>
        <div style={{ width: 500, height: 100 }}>
          <BarChart chartData={userData} />
        </div>
      </div>
    </>
    
  );
};

export default Aging;
