import React, { useState } from 'react';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      // Your login logic here...

      // Assuming login is successful, pass the access token to the parent component
      const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiQnJ5YW5BYmFyYWJhciIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6Im5ic2FAY2l2aWNtZHNnLmNvbS5waCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJBY2Nlc3NBbGxCcmFuY2hlcyIsIkFkbWluaXN0cmF0b3IiLCJBc3NldE1hc3RlckRhdGFNYWludGVuYW5jZSIsIkFzc2V0VHJhbnNhY3Rpb25FbmNvZGVyIiwiRmluYW5jZURvY1JldmlzZXIiLCJGb3JlaWduUE9FbmNvZGVyIiwiR2VuZXJhbExlZGdlckNsb3NlciIsIkdlbmVyYWxMZWRnZXJFbmNvZGVyIiwiR2VuZXJhbExlZGdlclBvc3RlciIsIkdlbmVyYWxMZWRnZXJSZXBvcnRlciIsIkxvY2FsUE9FbmNvZGVyIiwiTWFjaGluZUVURkFwcHJvdmVyIiwiTWFjaGluZUludmVudG9yeVRyYW5zYWN0aW9uRW5jb2RlciIsIk1hY2hpbmVNYXN0ZXJEYXRhTWFpbnRlbmFuY2UiLCJNYWNoaW5lUE9FbmNvZGVyIiwiTWFjaGluZVNlcmlhbEFsbG9jYXRvciIsIk1hdGVyaWFsTWFjaGluZSIsIk1hdGVyaWFsc0RlcHRFbmNvZGVyIiwiTWF0ZXJpYWxzRGVwdFBvc3RlciIsIk1hdGxGaW5hbmNlUGVyaW9kRW5jb2RlciIsIlBhcnRzTWF0bEZpbmFuY2VQZXJpb2RWaWV3ZXIiLCJQYXJ0c1RyYW5zZmVyRm9yd2FyZGVyIiwiUFJTQWxsb2NhdG9yIiwiUmVudGFsRXF1aXBNYWludEVuY29kZXIiLCJSZW50YWxTYWxlc0NsZXJrIiwiU2VydmljZUpPRW5jb2RlciIsIlNlcnZpY2VNYXN0ZXJEYXRhTWFpbnRlbmFuY2UiLCJTZXJ2aWNlUHJvZHVjdFN0YWZmIiwiU2VydmljZVNlY3JldGFyeSIsIlNlcnZpY2VUZWNobmljaWFuIiwiU2VydmljZVdJUEVuY29kZXIiLCJXYXJlaG91c2UiLCJXYXJyYW50eUVuY29kZXIiXSwibmJmIjoxNjkzODc3OTY5LCJleHAiOjE2OTM5NjQzNjksImlzcyI6Imh0dHBzOi8vY2l2aWMuY2l2aWNtZHNnLmNvbS5waCIsImF1ZCI6Imh0dHBzOi8vY2l2aWMuY2l2aWNtZHNnLmNvbS5waCJ9.HBu87jfzrY2A275Qwe7NxYQBOTb2XUCMUuaaB4JhgKA'; // Replace with the actual access token
      onLogin(username,password);
    } catch (error) {
      console.error('Error during login:', error);
      // Handle login error (e.g., display an error message to the user)
    }
  };

  return (
    <div>
      <h1>Login</h1>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>Login</button>
    </div>
  );
};

export default Login;
