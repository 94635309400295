export const UserData =
[{
    id: 1,
    month: 'Jan',
    userGain: 15677,
    userGain1: 15677,
    userGain2: 12677,
    userGain3: 12677,
    userGain4: 13677,
},
{
    id: 2,
    month: 'Feb',
    userGain: 13677,
    userGain1: 12677,
    userGain2: 12677,
    userGain3: 11677,
    userGain4: 11677,
},
{
    id: 3,
    month: 'Mar',
    userGain: 13677,
    userGain1: 13677,
    userGain2: 10677,
    userGain3: 10677,
    userGain4: 13677,
},
{
    id: 4,
    month: 'Apr',
    userGain: 13677,
    userGain1: 12677,
    userGain2: 12677,
    userGain3: 11677,
    userGain4: 11677,
},
{
    id: 5,
    month: 'May',
    userGain: 9677,
    userGain1: 9677,
    userGain2: 13677,
    userGain3: 13677,
    userGain4: 15677,
},
{
    id: 6,
    month: 'Jun',
    userGain: 15677,
    userGain1: 13677,
    userGain2: 13677,
    userGain3: 11677,
    userGain4: 11677,
},
{
    id: 7,
    month: 'Jul',
    userGain: 15677,
    userGain1: 15677,
    userGain2: 12677,
    userGain3: 12677,
    userGain4: 11677,
},
{
    id: 8,
    month: 'Aug',
    userGain: 11677,
    userGain1: 15677,
    userGain2: 15677,
    userGain3: 12677,
    userGain4: 12677,
},
{
    id: 9,
    month: 'Sep',
    userGain: 10677,
    userGain1: 10677,
    userGain2: 14677,
    userGain3: 14677,
    userGain4: 11677,
},
{
    id: 10,
    month: 'Oct',
    userGain: 11677,
    userGain1: 14677,
    userGain2: 14677,
    userGain3: 10677,
    userGain4: 10677,
},
{
    id: 11,
    month: 'Nov',
    userGain: 15677,
    userGain1: 15677,
    userGain2: 12677,
    userGain3: 12677,
    userGain4: 10677,
},
{
    id: 12,
    month: 'Dec',
    userGain: 10677,
    userGain1: 11677,
    userGain2: 11677,
    userGain3: 14677,
    userGain4: 14677,
},






]
export const UserData1 =
[{
    id: 1,
    month: 'Past',
    userGain: 15,
},]

export const UserData2 =
[{
    id: 1,
    month: 'Sent',
    userGain: 15,
},
{
    id: 2,
    month: 'Recieved',
    userGain: 15,
},
{
    id: 3,
    month: 'Open',
    userGain: 15,
},
{
    id: 4,
    month: 'Closed',
    userGain: 15,
},
{
    id: 5,
    month: 'Replied',
    userGain: 15,
},







]