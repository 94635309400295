import React, { useEffect, useState, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import  axios  from 'axios';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, ContextMenu, Page, ExcelExport, Inject, Toolbar, Edit, Grid, Filter} from '@syncfusion/ej2-react-grids';
import { pmsData, pmsGrid } from '../data/dummy';
import { Header } from '../components';
import * as XLSX from 'xlsx';

const Pmsmaster = () => {
  const [currentGrid, setCurrentGrid] = useState('pms');
  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ['ExcelExport','Add', 'Edit', 'Delete'];
  const editing = { allowDeleting: true, allowEditing: true };
  const customAttributes = { class: 'customcss' };
  const gridRef = useRef(null);
  const [data, setData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false); 
  const [filteredData, setFilteredData] = useState([]);
  const [uploadedPartIds, setUploadedPartIds] = useState([]);
  
  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary"});
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedDATA = XLSX.utils.sheet_to_json(sheet);
      const partIds = parsedDATA.map(row => row && row.PartId);
      setUploadedPartIds(partIds);
      setData(parsedDATA);
      //setDataFetched(false);
      console.log(parsedDATA);

      // Trigger data fetching when a file is uploaded
      //fetchData();
    }
  }

  useEffect(() => {
    if (uploadedPartIds.length > 0) {
      const cachedDataString = localStorage.getItem('mydata'); // Assuming you're getting cached data from localStorage
      if (typeof cachedDataString === 'string') {
        try {
          const cachedData = JSON.parse(cachedDataString);
          const filteredData = cachedData.filter(item => uploadedPartIds.includes(item.PartId));
          setFilteredData(filteredData);
          console.log('Filtered Data:', filteredData);
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      }
    }
  }, [uploadedPartIds]);
  const queryClient = useQueryClient();
  const cachedDataString = queryClient.getQueryData(['mydata']);
  const accessToken = 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiQnJ5YW5BYmFyYWJhciIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6Im5ic2FAY2l2aWNtZHNnLmNvbS5waCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJBY2Nlc3NBbGxCcmFuY2hlcyIsIkFkbWluaXN0cmF0b3IiLCJBc3NldE1hc3RlckRhdGFNYWludGVuYW5jZSIsIkFzc2V0VHJhbnNhY3Rpb25FbmNvZGVyIiwiRmluYW5jZURvY1JldmlzZXIiLCJGb3JlaWduUE9FbmNvZGVyIiwiR2VuZXJhbExlZGdlckNsb3NlciIsIkdlbmVyYWxMZWRnZXJFbmNvZGVyIiwiR2VuZXJhbExlZGdlclBvc3RlciIsIkdlbmVyYWxMZWRnZXJSZXBvcnRlciIsIkxvY2FsUE9FbmNvZGVyIiwiTWFjaGluZUVURkFwcHJvdmVyIiwiTWFjaGluZUludmVudG9yeVRyYW5zYWN0aW9uRW5jb2RlciIsIk1hY2hpbmVNYXN0ZXJEYXRhTWFpbnRlbmFuY2UiLCJNYWNoaW5lUE9FbmNvZGVyIiwiTWFjaGluZVNlcmlhbEFsbG9jYXRvciIsIk1hdGVyaWFsTWFjaGluZSIsIk1hdGVyaWFsc0RlcHRFbmNvZGVyIiwiTWF0ZXJpYWxzRGVwdFBvc3RlciIsIk1hdGxGaW5hbmNlUGVyaW9kRW5jb2RlciIsIlBhcnRzTWF0bEZpbmFuY2VQZXJpb2RWaWV3ZXIiLCJQYXJ0c1RyYW5zZmVyRm9yd2FyZGVyIiwiUFJTQWxsb2NhdG9yIiwiUmVudGFsRXF1aXBNYWludEVuY29kZXIiLCJSZW50YWxTYWxlc0NsZXJrIiwiU2VydmljZUpPRW5jb2RlciIsIlNlcnZpY2VNYXN0ZXJEYXRhTWFpbnRlbmFuY2UiLCJTZXJ2aWNlUHJvZHVjdFN0YWZmIiwiU2VydmljZVNlY3JldGFyeSIsIlNlcnZpY2VUZWNobmljaWFuIiwiU2VydmljZVdJUEVuY29kZXIiLCJXYXJlaG91c2UiLCJXYXJyYW50eUVuY29kZXIiXSwibmJmIjoxNjk2ODk5MjE3LCJleHAiOjE2OTY5ODU2MTcsImlzcyI6Imh0dHBzOi8vY2l2aWMuY2l2aWNtZHNnLmNvbS5waCIsImF1ZCI6Imh0dHBzOi8vY2l2aWMuY2l2aWNtZHNnLmNvbS5waCJ9.W53k3NiKCZa8rz_V2VquHeBAJ4oJA7NvQgef-NrcSWE';
  const config = {
    method: 'POST',
    responseType: 'json',
    headers: {
      'Authorization': accessToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "IsGroup": "No",
      // "page": page,
      // "pageSize": pageSize
    })
  };

  useEffect(() => {
    if (!dataFetched) { // Check if data has been fetched before making another request
      const fetchData = async () => {
        try {
          const response = await axios.post('https://system.civicmdsg.com.ph/CivicSystemWeb/api/ICPortalResources/ProductQtyFileExtract', {}, config);

          // Assuming response.data is an array of objects
          const cachedData = response.data;

          if (uploadedPartIds.length > 0) {
            const filteredData = cachedData.filter(item => uploadedPartIds.includes(item && item.PartId));
            setFilteredData(filteredData);
            console.log('Filtered Data:', filteredData);
          }

          setDataFetched(true); // Data has been fetched
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }
  }, [dataFetched, uploadedPartIds]);


  
  const handleGridChange = (gridName) => {
    setCurrentGrid(gridName);
  };
  const clearFilters = () => {
    if (gridRef.current) {
      gridRef.current.clearFiltering(); // Call the clearFiltering method
    }
  };
  const FilterCell = (props) => {
    const column = props.column;
    return (
      <div>
        <button onClick={clearFilters}>Clear Filters</button>
        <input {...column.getFilterProps()} />
      </div>
    );
  };
 
  
  
  
  
  
  
  

  return (
    <div className="m-2 md:m-5 mt-24 p-2 md:p-5 bg-white rounded-3xl">
      <Header category="PMS" title="Master File" />
      <div className="grid-container">
        <GridComponent
          id="pmsGrid"
          dataSource={filteredData}
          enableHover={true}
          selectionSettings={selectionsettings}
          toolbar={toolbarOptions}
          editSettings={editing}
          allowTextWrap={false}
          allowResizing={false}
          allowScrolling={true}
          allowFiltering={false}
          width="100%"
          ref={gridRef}
          filterSettings={{
            type: 'Excel', // Set the filter type to 'Menu' for filter menus
          }}
         
         
        >
           <input type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
        />
          <ColumnsDirective>
  {currentGrid === 'pms' ? (
    pmsGrid.map((item, index) => (
      <ColumnDirective
        key={index}
        field={item.field}
        headerText={item.headerText}
        textAlign={item.textAlign}
        width={item.width}
        format={item.format}
        customAttributes={customAttributes}
        allowEditing={item.field === 'APRD QTY'}
        filterTemplate={FilterCell}
      />
    ))
  ) : null}
</ColumnsDirective>

          <Inject services={[Toolbar, ExcelExport, Resize, Edit, Filter]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default Pmsmaster;
