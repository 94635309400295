import React from 'react'

const Bar = () => {
  return (
    <div>
      
    </div>
  )
}

export default Bar
