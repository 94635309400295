import React, { useEffect, useState, useRef } from 'react';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import  axios  from 'axios';
import { GridComponent, ColumnsDirective, ColumnDirective, ExcelExport, Inject, Toolbar, Resize } from '@syncfusion/ej2-react-grids';
import { combinedGrid, vceGrid, vceGrid2, vceGrid3, pwideGrid, mrp2Data, VolvoCEData } from '../data/dummy';
import { Header } from '../components';
import * as XLSX from 'xlsx';
import useIndexedDB from '../components/IndexedDB';

const Vtc = () => {
     
      console.log('Component is rendering...');
      const clusterContainerStyle = {
        width: '120%',
      };
      const selectionsettings = { persistSelection: true };
      const toolbarOptions = ['ExcelExport']; // Add other desired options for the toolbar if needed
      const customAttributes = { class: 'customcss' };
     const [data, setData] = useState([]);
     const { initializeDB, storeDataInIndexedDB, getDataFromIndexedDB } = useIndexedDB();
      const [dataFetched, setDataFetched] = useState(false); 
      //const [filteredData, setFilteredData] = useState([]);
      const [allData, setAllData] = useState([]);
      //const [ setGroupedData] = useState([]);
      const [uploadedPartIds, setUploadedPartIds] = useState([]);
    //   const [pwideGrid, setPWideGrid] = useState([]);
    //   const [vceGrid, setVceGrid] = useState([]); // Default grid source
    //   const [vceGrid2, setVceGrid2] = useState([]);
    //   const [vceGrid3, setVceGrid3] = useState([]);
      const [selectedGrid, setSelectedGrid] = useState('cluster1');
      // After storing data, you can retrieve and log it
    
    
      // Assuming you have five file input elements with corresponding file types
    const fileTypes = ['Product QTY', 'Cluster 1', 'Cluster 2', 'Cluster 3', 'Pwide'];
    
    // Modify your state to store parsed data in an object
    const [parsedData, setParsedData] = useState({});
    
    const handleFileUpload = async (e, fileType) => {
      const reader = new FileReader();
      reader.readAsBinaryString(e.target.files[0]);
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
    
        // Read the first sheet of the workbook
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const currentParsedData = XLSX.utils.sheet_to_json(sheet);
    
        // Update the state with the parsed data for the current file type
        setParsedData((prevData) => ({
          ...prevData,
          [fileType]: currentParsedData,
        }));
      };
    };
    
    const partIdsToFilter = [
      79812,
      62199,
      830,
      79813,
      79814,
      79815,
      79816,
      36275,

    ];
        console.log('parsedData:', parsedData);
        let filteredData = [];
    let filteredData2 = [];
    let filteredData3 = [];
    let filteredData4 = [];
    let filteredData5 = [];
        
        if (parsedData['Product QTY']) {
          filteredData = parsedData['Product QTY'].filter(
            (row) => partIdsToFilter.includes(row && row['Part Id'])
          );
          console.log('Filtered Data for Product QTY:', filteredData);
        }
        
        if (parsedData['Cluster 1']) {
          filteredData2 = parsedData['Cluster 1'].filter(
            (row) => partIdsToFilter.includes(row && row['item_id'])
          );
          console.log('Filtered Data for Cluster 1:', filteredData2);
        }
        
        if (parsedData['Cluster 2']) {
          filteredData3 = parsedData['Cluster 2'].filter(
            (row) => partIdsToFilter.includes(row && row['item_id'])
          );
          console.log('Filtered Data for Cluster 2:', filteredData3);
        }
        
        if (parsedData['Cluster 3']) {
          filteredData4 = parsedData['Cluster 3'].filter(
            (row) => partIdsToFilter.includes(row && row['item_id'])
          );
          console.log('Filtered Data for Cluster 3:', filteredData4);
        }
        
        if (parsedData['Pwide']) {
          filteredData5 = parsedData['Pwide'].filter(
            (row) => partIdsToFilter.includes(row && row['item_id'])
          );
          console.log('Filtered Data for Pwide:', filteredData5);
        }
    //     const filteredData = parsedData['Product QTY'].filter(row => partIdsToFilter.includes(row && row['Part Id']));
    // const filteredData2 = parsedData['Cluster 1'].filter(row => partIdsToFilter.includes(row && row['item_id']));
    // const filteredData3 = parsedData['Cluster 2'].filter(row => partIdsToFilter.includes(row && row['item_id']));
    // const filteredData4 = parsedData['Cluster 3'].filter(row => partIdsToFilter.includes(row && row['item_id']));
    // const filteredData5 =parsedData['Pwide'].filter(row => partIdsToFilter.includes(row && row['item_id']));
    
    const filteredDataWithNumericOnHand = filteredData.map(item => {
      const onHandValue = parseInt(item.AVAIL); // Parse the "OnHand" value as an integer
      const partNumber = (item.PartNumber);
      const desc = (item.Desc);
      const category = (item.Catergory);
      return {
        ...item,
        partNumber: partNumber,
        desc: desc,
        category: category,
        OnHand: onHandValue,
       // OnHandNumeric: onHandValue,
      };
    });
    
    const filteredData30 = filteredData2.map(item => {
      const day_avgValue = parseFloat(item['30_day_avg']);
       const roundedDayAvg = Math.round(day_avgValue * 10) / 10;
     
      return {
        ...item,
        day_avg: roundedDayAvg,
      
      };
      
    });
    const filteredData30c2 = filteredData3.map(item => {
      const day_avgValue = parseFloat(item['30_day_avg']);
       const roundedDayAvg = Math.round(day_avgValue * 10) / 10;
     
      return {
        ...item,
        day_avg: roundedDayAvg,
      
      }; 
    });
    const filteredData30c3 = filteredData4.map(item => {
      const day_avgValue = parseFloat(item['30_day_avg']);
       const roundedDayAvg = Math.round(day_avgValue * 10) / 10;
     
      return {
        ...item,
        day_avg: roundedDayAvg,
      
      }; 
    });
    
    const filteredData30pw = filteredData5.map(item => {
      const day_avgValue = parseFloat(item['30_day_avg']);
       const roundedDayAvg = Math.round(day_avgValue * 10) / 10;
     
      return {
        ...item,
        day_avg: roundedDayAvg,
      
      }; 
    });
    
    
    // Now, you can use filteredDataWithNumericOnHand for further processing
    //const partNumber = 'PartNumber';
    //const desc = 'Desc';
    // Group and sum the "OnHand" values based on conditions
    const groupedData = partIdsToFilter.map((partId) => {
      const filteredPartData = filteredDataWithNumericOnHand.filter((item) => 
      item['Part Id'] === partId && 
      item['CLUSTER'] === 1 
    );
    const filteredPartData2 = filteredDataWithNumericOnHand.filter((item) => 
      item['Part Id'] === partId && 
      item['CLUSTER'] === 2 
    );
    const filteredPartData3 = filteredDataWithNumericOnHand.filter((item) => 
      item['Part Id'] === partId && 
      item['CLUSTER'] === 3 
    );
    const filteredPartDatapw = filteredDataWithNumericOnHand.filter((item) => 
      item['Part Id'] === partId
    );
      const filtered12Data = filteredDataWithNumericOnHand.filter(
        (item) => item['Part Id'] === partId && item['WH'] === 12
      );
      const filtered30Data = filteredData30.filter(
        (item) => item['item_id'] === partId
      );
      const filtered30Datac2 = filteredData30c2.filter(
        (item) => item['item_id'] === partId
      );
      const filtered30Datac3 = filteredData30c3.filter(
        (item) => item['item_id'] === partId
      );
      const filtered30Datapw = filteredData30pw.filter(
        (item) => item['item_id'] === partId
      );
    
      const totalOnHand = filteredPartData.reduce((sum, item) => sum + item.AVAIL, 0);
      const totalOnHand2 = filteredPartData2.reduce((sum, item) => sum + item.AVAIL, 0);
      const totalOnHand3 = filteredPartData3.reduce((sum, item) => sum + item.AVAIL, 0);
      const totalOnHandpw = totalOnHand + totalOnHand2 + totalOnHand3;
      const OnHand = filtered12Data.reduce((sum, item) => sum + item.AVAIL, 0);
      const day_avg = parseFloat(filtered30Data[0]?.day_avg || 0).toFixed(2);
      const day_avgc2 = parseFloat(filtered30Datac2[0]?.day_avg || 0).toFixed(2);
      const day_avgc3 = parseFloat(filtered30Datac3[0]?.day_avg || 0).toFixed(2);
      const day_avgpw = parseFloat(filtered30Datapw[0]?.day_avg || 0).toFixed(2);
      const cons = day_avg !== 0 ? Math.ceil(totalOnHand / day_avg * 10) / 10 : 0;
      const consc2 = day_avgc2 !== 0 ? Math.ceil(totalOnHand2 / day_avgc2 * 10) / 10 : 0;
      const consc3 = day_avgc3 !== 0 ? Math.ceil(totalOnHand3 / day_avgc3 * 10) / 10 : 0;
      const denominator = parseFloat(day_avg) + parseFloat(day_avgc2) + parseFloat(day_avgc3);
      console.log('Denominator:', denominator)
      const conspw = denominator !== 0
      ? Math.ceil((totalOnHandpw / denominator) * 10) / 10
      : 0;
      let result;
    if (cons > 6.5) {
      result = "OVERSTOCK";
    } else if (cons >= 3.5) {
      result = "SUFFICIENT";
    } else if (cons === 0) {
      result = "NOT APPLICABLE";
    } else {
      result = "INSUFFICIENT";
    }
    let resultc2;
    if (consc2 > 6.5) {
      resultc2 = "OVERSTOCK";
    } else if (consc2 >= 3.5) {
      resultc2 = "SUFFICIENT";
    } else if (consc2 === 0) {
      resultc2 = "NOT APPLICABLE";
    } else {
      resultc2 = "INSUFFICIENT";
    }
    let resultc3;
    if (consc3 > 6.5) {
      resultc3 = "OVERSTOCK";
    } else if (consc3 >= 3.5) {
      resultc3 = "SUFFICIENT";
    } else if (consc3 === 0) {
      resultc3 = "NOT APPLICABLE";
    } else {
      resultc3 = "INSUFFICIENT";
    }
    let resultpw;
    if (conspw > 6.5) {
      resultpw = "OVERSTOCK";
    } else if (conspw >= 3.5) {
      resultpw = "SUFFICIENT";
    } else if (conspw === 0) {
      resultpw = "NOT APPLICABLE";
    } else {
      resultpw = "INSUFFICIENT";
    }
    
    const onORD = filteredPartData.reduce((sum, item) => sum + item['ON ORD'], 0);
    const onORDc2 = filteredPartData2.reduce((sum, item) => sum + item['ON ORD'], 0);
    const onORDc3 = filteredPartData3.reduce((sum, item) => sum + item['ON ORD'], 0);
    const onORDpw = onORD + onORDc2 + onORDc3;
    // Assuming filteredPartData is an array of objects with properties 'partNumber' and 'category'
    const concatenatedValue = `${filteredPartData[0]?.PartNumber || ''}${filteredPartData[0]?.Category || ''}`; // Use the first item in the filtered array
    
    // Now concatenatedData contains the original properties along with a new 'concatenatedValue' property
    const consfc2 = day_avg !== 0 && !isNaN(day_avg) ? Math.ceil(onORD + totalOnHand) / day_avg : 0;
    const c2consfc2 = day_avgc2 !== 0 && !isNaN(day_avgc2) ? Math.ceil(onORDc2 + totalOnHand2) / day_avgc2 : 0;
    const c3consfc2 = day_avgc3 !== 0 && !isNaN(day_avgc3) ? Math.ceil(onORDc3 + totalOnHand3) / day_avgc3 : 0;
    const pwconsfc2 = day_avgpw !== 0 && !isNaN(day_avgpw) ? Math.ceil(onORDpw + totalOnHandpw) / day_avgpw : 0;
    
    let result1;
    if (consfc2 > 6.5) {
      result1 = "OVERSTOCK";
    } else if (consfc2 >= 3.5) {
      result1 = "SUFFICIENT";
    } else if (consfc2 === 0) {
      result1 = "NOT APPLICABLE";
    } else {
      result1 = "INSUFFICIENT";
    }
    let c2result1;
    if (c2consfc2 > 6.5) {
      c2result1 = "OVERSTOCK";
    } else if (c2consfc2 >= 3.5) {
      c2result1 = "SUFFICIENT";
    } else if (c2consfc2 === 0) {
      c2result1 = "NOT APPLICABLE";
    } else {
      c2result1 = "INSUFFICIENT";
    }
    let c3result1;
    if (c3consfc2 > 6.5) {
      c3result1 = "OVERSTOCK";
    } else if (c3consfc2 >= 3.5) {
      c3result1 = "SUFFICIENT";
    } else if (c3consfc2 === 0) {
      c3result1 = "NOT APPLICABLE";
    } else {
      c3result1 = "INSUFFICIENT";
    }
    let pwresult1;
    if (pwconsfc2 > 6.5) {
      pwresult1 = "OVERSTOCK";
    } else if (pwconsfc2 >= 3.5) {
      pwresult1 = "SUFFICIENT";
    } else if (pwconsfc2 === 0) {
      pwresult1 = "NOT APPLICABLE";
    } else {
      pwresult1 = "INSUFFICIENT";
    }
    
    
    const fsn = filtered30Data[0]?.item_movement;
    const fsnc2 = filtered30Datac2[0]?.item_movement;
    const fsnc3 = filtered30Datac3[0]?.item_movement;
    const fsnpw = filtered30Datapw[0]?.item_movement;
    
    const trend = filtered30Data[0]?.Trend;
    const trendc2 = filtered30Datac2[0]?.Trend;
    const trendc3 = filtered30Datac3[0]?.Trend;
    const trendpw = filtered30Datapw[0]?.Trend;
    
    
      return {
        'Part Id': partId,
        'CONCAT': concatenatedValue,
        'PartNumber': filteredPartData[0]?.partNumber || '', // Include PartNumber in the result
        'Desc': filteredPartData[0]?.desc || '', // Include Desc in the result
        'Category' : filteredPartData[0]?.Category || '',
        '12 ONHAND' : OnHand,
        'C1 ON HAND': totalOnHand,
        'C1 30 DAY' : day_avg,
        'CONS FC' : cons,
        'CURRENT STOCKING STATUS' : result,
        'C1 ON ORD' : onORD,
        'CONS FC2': consfc2.toFixed(1),
        'ONORD STOCKING STATUS' : result1,
        'FSN': fsn,
        'Trend': (trend * 100).toFixed(2) + '%',
        'C2 ON HAND': totalOnHand2,
        'C2 30 DAY' : day_avgc2,
        'C2 CONS FC' : consc2,
        'C2 CURRENT STOCKING STATUS' : resultc2,
        'C2 ON ORD' : onORDc2,
        'C2 CONS FC2':  c2consfc2.toFixed(1),
        'C2 ONORD STOCKING STATUS' : c2result1,
        'C2 FSN': fsnc2,
        'C2 Trend': (trendc2 * 100).toFixed(2) + '%',
        'C3 ON HAND': totalOnHand3,
        'C3 30 DAY' : day_avgc3,
        'C3 CONS FC' : consc3,
        'C3 CURRENT STOCKING STATUS' : resultc3,
        'C3 ON ORD' : onORDc3,
        'C3 CONS FC2':  c3consfc2.toFixed(1),
        'C3 ONORD STOCKING STATUS' : c3result1,
        'C3 FSN': fsnc3,
        'C3 Trend': (trendc3 * 100).toFixed(2) + '%',
        'PW ON HAND': totalOnHandpw,
        'PW 30 DAY' : day_avgpw,
        'PW CONS FC' : conspw,
        'PW CURRENT STOCKING STATUS' : resultpw,
        'PW ON ORD' : onORDpw,
        'PW CONS FC2':  pwconsfc2.toFixed(1),
        'PW ONORD STOCKING STATUS' : pwresult1,
        'PW FSN': fsnpw,
        'PW Trend': (trendpw * 100).toFixed(2) + '%',
      };
    });
    
    
     
    
      return (
        <div className="m-2 md:m-3 mt-24 p-2 md:p-10 bg-white rounded-3xl grid-container"> {/* Add grid container here */}
          <Header category="Lubes Monitoring" title="SDLG" />
          <div className="file-upload-container">
      <label htmlFor="productQty">Upload Product QTY:</label>
      <label htmlFor="cluster1">Upload Cluster 1:</label>
      <label htmlFor="cluster2">Upload Cluster 2:</label>
      <label htmlFor="cluster3">Upload Cluster 3:</label>
      <label htmlFor="pwide">Upload Pwide:</label>
    
      <input id="productQty" type="file" accept=".xls, .xlsx" onChange={(e) => handleFileUpload(e, 'Product QTY')} />
      <input id="cluster1" type="file" accept=".xls, .xlsx" onChange={(e) => handleFileUpload(e, 'Cluster 1')} />
      <input id="cluster2" type="file" accept=".xls, .xlsx" onChange={(e) => handleFileUpload(e, 'Cluster 2')} />
      <input id="cluster3" type="file" accept=".xls, .xlsx" onChange={(e) => handleFileUpload(e, 'Cluster 3')} />
      <input id="pwide" type="file" accept=".xls, .xlsx" onChange={(e) => handleFileUpload(e, 'Pwide')} />
    </div>
    
          
          <div style={{ display: 'flex' }}>
            <div style={{ width: '100%' }}>
              <h4 style={{ fontWeight: 'bold', fontSize: '18px' }}>Cluster 1</h4>
              <div style={clusterContainerStyle}>
                <GridComponent dataSource={groupedData} >
                
    
                  <ColumnsDirective>
                    {vceGrid.map((column) => (
                      <ColumnDirective
                        key={column.field}
                        field={column.field}
                        headerText={column.headerName}
                        width={column.width}
                        format={column.format}
                      />
                    ))}
                  </ColumnsDirective>
                </GridComponent>
              </div>
              <h4 style={{ fontWeight: 'bold', fontSize: '18px' }}>Cluster 2</h4>
              <div style={clusterContainerStyle}>
                <GridComponent dataSource={groupedData}>
                  <ColumnsDirective>
                    {vceGrid2.map((column) => (
                      <ColumnDirective
                        key={column.field}
                        field={column.field}
                        headerText={column.headerName}
                        width={column.width}
                        format={column.format}
                      />
                    ))}
                  </ColumnsDirective>
                </GridComponent>
              </div>
              <h4 style={{ fontWeight: 'bold', fontSize: '18px' }}>Cluster 3</h4>
              <div style={clusterContainerStyle}>
                <GridComponent dataSource={groupedData}>
                  <ColumnsDirective>
                    {vceGrid3.map((column) => (
                      <ColumnDirective
                        key={column.field}
                        field={column.field}
                        headerText={column.headerName}
                        width={column.width}
                        format={column.format}
                      />
                    ))}
                  </ColumnsDirective>
                </GridComponent>
              </div>
              <h4 style={{ fontWeight: 'bold', fontSize: '18px' }}>PHILWIDE</h4>
              <div style={clusterContainerStyle}>
                <GridComponent dataSource={groupedData}>
                  <ColumnsDirective>
                    {pwideGrid.map((column) => (
                      <ColumnDirective
                        key={column.field}
                        field={column.field}
                        headerText={column.headerName}
                        width={column.width}
                        format={column.format}
                      />
                    ))}
                  </ColumnsDirective>
                </GridComponent>
              </div>
            </div>
        </div>
          {/* <GridComponent
            id="VCE"
            dataSource={groupedData} 
            enableHover={true}
            selectionSettings={selectionsettings}
            toolbar={toolbarOptions}
            allowTextWrap={false}
            allowResizing={false}
            allowScrolling={true}
            width="120%"
          >
             <input type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            />
            <ColumnsDirective>
              {combinedGrid.map((item, index) => (
                <ColumnDirective
                  key={index}
                  {...item}
                  header={index === 0 ? headerStyle : undefined}
                  customAttributes={customAttributes}
                  
                />
              ))}
            </ColumnsDirective>
            <Inject services={[Toolbar, ExcelExport, Resize]} />
          </GridComponent>
        </div> */}
        </div>
      );
    };

export default Vtc;
