import React from "react";
import { Bar } from "react-chartjs-2";
import {Chart as ChartJS, scales} from "chart.js/auto";

function BarChart  ({chartData})  {
  
    return (
        <Bar
          data={chartData}
          options={{
            maintainAspectRatio: false,
            scales: {
              y: { display: false },
              x: {
                grid: { display: false },
              },
            },
            plugins: {
              legend: { display: false },
            },
            layout: {
              padding: { left: 0, right: 0, top: 0, bottom: 0 },
            },
            elements: {
              bar: {
                borderRadius: 15
              },
            },
          }}
        />
      );
      
      
      

 
  
}

export default BarChart;
