import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import civic from '../data/civic.jpg';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { AiOutlineMenu } from 'react-icons/ai';

// Replace '../data/dummy' and '../contexts/ContextProvider' with your actual paths
import { links } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';

const UserProfile = () => {
  const { isClicked, handleClick } = useStateContext();

  return (
    <div className="profile-box">
      <TooltipComponent content="Profile" position="BottomCenter">
        <div
          className="flex rounded-full items-center gap-2 cursor-pointer p-1 hover:bg-light-gray"
          onClick={() => handleClick('userProfile')}
        >
          <p>
            <span className="text-gray-600 text-8px">Account</span>{' '}
            <span className="text-gray-600 font-bold ml-1 text-8px">NAP@CIVICMDSG.COM..PH</span>
          </p>
          <MdKeyboardArrowDown className="text-gray-600 text-10px" />
        </div>
      </TooltipComponent>
      {isClicked.userProfile && (
        <div>
          {/* Your User Profile content */}
          <UserProfile />
        </div>
      )}
    </div>
  );
};

const Sidebar = () => {
  const { currentColor, activeMenu, setactiveMenu, handleToggleSidebar, isClicked, handleClick } = useStateContext();
  const [activeSublink, setActiveSublink] = useState(null);
  const [inventoryExpanded, setInventoryExpanded] = useState(false);
  const [showPmsSublinks, setShowPmsSublinks] = useState(false);

  const handleActiveMenu = () => setactiveMenu(!activeMenu);
  const location = useLocation();
  const handleToggleInventory = () => {
    console.log('handleToggleInventory called'); // Add this line
    setInventoryExpanded((prevState) => !prevState);
  };

  const handleToggleSublinks = (sublinkName) => {
    if (sublinkName === 'CROSS DOCK' || sublinkName === 'DIRECT SHIPMENT' || sublinkName === 'FILTER' || sublinkName === 'CLUSTER') {
      setActiveSublink(null);
    } else {
      setActiveSublink((prevSublink) => (prevSublink === sublinkName ? null : sublinkName));
    }
  };
  
  
  const handleTogglePmsSublinks = () => {
    setShowPmsSublinks(!showPmsSublinks);
  };
  
  const activeLinkStyle = {
    backgroundColor: 'lightgray',
    fontWeight: 'bold',
  };

  const activeClassName = 'active-link';
  const isActive = (path) => {
    return location.pathname === path;
  };
  const activeLink = ' flex items-center gap-1 pl-1 pt-1 pb-1 rounded-lg text-white text-xs m-2';
  const normalLink = ' flex items-center gap-1 pl-1 pt-1 pb-1 rounded-lg text-xs text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-gray-100 m-2';
  const titleClass = ' text-xs text-gray-700  m-1 mt-3  text-left dark:text-gray-200';
  const nestedLinkClass = 'nested-link';
  return (
     
    <div className={`sidebar-container ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto  ${activeMenu ? '' : 'w-15%'} bg-gray-100`}>
  {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            {/* Logo Section */}
            <div>
              <Link
                to="/"
                onClick={() => handleToggleSidebar(false)}
                className={`items-center  ml-3  flex text-xs  tracking-tight dark:text-white text-slate-900 ${activeMenu ? '' : 'hidden'}`}
              >
                <span></span>
              </Link>

              {/* UserProfile */}
              <TooltipComponent content="Profile" position="Center">
                <div
                  className="profile-box bg-gray-100 flex items-center  cursor-pointer  hover:bg-light-gray "
                  onClick={() => handleClick('userProfile')}
                >
                  <p>
                    <span className="text-gray-600  text-8px">ACCOUNT</span>{' '}
                    <span className="text-gray-600  text-8px">NAP@CIVICMDSG.COM.PH</span>
                  </p>
                  <MdKeyboardArrowDown className="text-gray-600 text-8px" />
                </div>
              </TooltipComponent>
            </div>
            <button
              title="Menu"
              onClick={handleActiveMenu}
              className={`text-2xl rounded-full  hover:bg-gray-500  block md:hidden ${currentColor}`}
            >
              <AiOutlineMenu />
            </button>

            {/* Close Icon */}
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setactiveMenu((prevactiveMenu) => !prevactiveMenu)}
                className="text-xs rounded-full  hover:bg-gray-500  block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>

           {/* Navigation Links */}
    {links.map((item) => (
      <div key={item.title}>
        <div>
          <p className={`font-normal ${titleClass}`}>{item.title}</p>
        </div>
        {item.links.map((linkItem) => (
          <React.Fragment key={linkItem.name}>
            <div
              className={`flex items-center pl-1 text-gray-700 dark:text-gray-200 text-xs cursor-pointer ${
                linkItem.icon ? 'has-icon' : ''
              } ${
                isActive(`/${linkItem.name}`) ? 'active-sublink' : 'sublink-hover'
              }`}
              onClick={() => {
                if (linkItem.name !== 'CROSS DOCK' && linkItem.name !== 'FILTERS'&& linkItem.name !== 'DIRECT SHIPMENT' && linkItem.name !== 'CLUSTER') {
                  handleToggleSublinks(item.title);
                }
                if (linkItem.name === 'PMS') {
                  handleTogglePmsSublinks();
                }
              }}
            >
              {linkItem.icon}  <NavLink to={`/${linkItem.name}`}>
    <span>{linkItem.name}</span>
  </NavLink>
            </div>
            {activeSublink === item.title && (linkItem.name === 'LUBES' || linkItem.name === 'INVENTORY') && (
              <ul className="pl-4 text-gray-700 text-xs cursor-pointer">
                {linkItem.sublinks && linkItem.sublinks.map((sublink) => (
                  <li
                    key={sublink.name}
                    className={`text-gray-700 dark:text-gray-200 dark:hover:text-black ${
                      isActive(`/${sublink.name}`) ? 'active-sublink' : 'sublink-hover'
                    } ${
                      sublink.pcColor === 'green-600'
                        ? 'text-green-600'
                        : sublink.pcColor === 'orange-600'
                        ? 'text-orange-600'
                        : sublink.pcColor === 'gray-600'
                        ? 'text-gray-200'
                        : ''
                    }`}
                  >
                    <NavLink to={`/${sublink.name}`} >{sublink.name}</NavLink>
                  </li>
                ))}
              </ul>
            )}
          </React.Fragment>
        ))}
      </div>
    ))}
  </>
)}
    </div>
  );
};

export default Sidebar;