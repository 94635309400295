import React from 'react'

const LineChart = () => {
  return (
    <div>
     
    </div>
  )
}

export default LineChart
